import classNames from 'classnames'
import moment from 'moment'
import React from 'react'

import Filters from './Filters'
import Navigation from './Navigation'

const Header = (props) => {
  const { dades, isMobile } = props

  return (
    <div className='schedule-header'>
      <Filters {...props} />
      <div className='days-navigation'>
        <Navigation isMobile={isMobile} />
        <div className='header-days'>
          <div className='header-days-row'>
            {dades &&
              dades.map(({ day, name }, index) => {
                const dayClass = classNames({
                  date: true,
                  empty: !day,
                  ranges: !day && index === 0,
                  timeRanges: !day && index === 1,
                })

                return (
                  <div key={index} className={dayClass}>
                    <div className='daytitle'>
                      {!name ? (
                        <>
                          <span className='weekday'>
                            {day && moment(day, 'YYYY-MM-DD').format('dddd')}
                          </span>
                          <span className='dayandmonth'>
                            {day && moment(day, 'YYYY-MM-DD').format('DD MMM')}
                          </span>
                        </>
                      ) : (
                        <span>{name}</span>
                      )}
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header

import classNames from 'classnames'
import React from 'react'

import { useBaseStore } from '../store'
import Course from './Course'

export function getTimeRangeQuantity(quantity, range) {
  if (!quantity) return 1
  const quantityFound = quantity.find((q) => q.nom === range)?.quantity
  if (quantityFound === 0) return 0
  if (!quantityFound) return 1
  return quantityFound
}

function renderCourses(
  currentCourse,
  timeRangeQuantity,
  quantity,
  courseSize,
  disableRooms,
) {
  if (timeRangeQuantity > currentCourse.length && disableRooms) {
    return Object.keys([...Array(timeRangeQuantity)]).map((index) => {
      if (currentCourse[index]) {
        return (
          <Course
            key={index}
            range={currentCourse[index]}
            quantity={quantity}
          />
        )
      }
      return (
        <div
          className='emptyCourse'
          key={index}
          style={{ height: courseSize, minHeight: courseSize }}
        >
          <div className='range-title-wrapper' />
        </div>
      )
    })
  }
  return currentCourse.map((y, ind) => {
    return <Course key={ind} range={y} quantity={quantity} />
  })
}

function generateCourses(
  courses,
  quantity,
  disableRooms,
  timeRangeQuantity,
  courseSize,
) {
  return Object.keys(courses).map((index) => {
    if (!courses[index].length) {
      return <Course key={index} range={courses[index]} quantity={quantity} />
    }
    if (courses[index].length > 1 && !disableRooms) {
      return (
        <div
          style={{
            display: 'flex',
            gap: '5px',
          }}
        >
          {renderCourses(
            courses[index],
            timeRangeQuantity,
            quantity,
            courseSize,
            disableRooms,
          )}
        </div>
      )
    }
    return renderCourses(
      courses[index],
      timeRangeQuantity,
      quantity,
      courseSize,
      disableRooms,
    )
  })
}

const EmptyCourse = (props) => {
  const { timeRangeQuantity, courseSize } = props
  return Object.keys([...Array(timeRangeQuantity)]).map((index) => (
    <div
      className='emptyCourse'
      key={index}
      style={{
        height: `${courseSize}px`,
        minHeight: `${courseSize}px`,
      }}
    >
      <div className='range-title-wrapper' />
    </div>
  ))
}

const TimeRanges = (props) => {
  const {
    range,
    height,
    timeRangeQuantity,
    ranges,
    quantity,
    disableRooms,
    courseSize,
  } = props
  return (
    <div
      key={range}
      className='time-range'
      data-range={range}
      style={{
        height: `${height}px`,
        minHeight: `${height}px`,
        display: timeRangeQuantity === 0 && 'none',
      }}
    >
      {ranges[range] && Object.keys(ranges[range]).length ? (
        generateCourses(
          ranges[range],
          quantity,
          disableRooms,
          timeRangeQuantity,
          courseSize,
        )
      ) : (
        <EmptyCourse
          timeRangeQuantity={timeRangeQuantity}
          courseSize={courseSize}
        />
      )}
    </div>
  )
}

const Courses = (props) => {
  const { ranges, quantity, rooms } = props
  const { courseSize, courseGap, disableRooms } = useBaseStore(
    ({ config }) => ({
      // Configuration
      disableRooms: config.disableRooms,
      courseSize: config.courseSize,
      courseGap: config.courseGap,
    }),
  )

  if (ranges.length) {
    return ranges.map((range) => (
      <Course key={range.nom} range={range} quantity={quantity} rooms={rooms} />
    ))
  }

  return Object.keys(ranges).map((range) => {
    const timeRangeQuantity = getTimeRangeQuantity(quantity, range)
    const height =
      timeRangeQuantity * courseSize + (timeRangeQuantity - 1) * courseGap

    return (
      <TimeRanges
        range={range}
        height={height}
        timeRangeQuantity={timeRangeQuantity}
        ranges={ranges}
        quantity={quantity}
        disableRooms={disableRooms}
        courseSize={courseSize}
      />
    )
  })
}

export const Ranges = (props) => {
  const { ranges, id, day, isMobile, quantity, rooms } = props
  const rangeClass = classNames({
    classes: true,
    empty: !day,
    ranges: !day && id === 0,
    timeRanges: !day && id === 1,
    hidden: isMobile && id > 1,
  })

  return (
    <div className={rangeClass}>
      <Courses ranges={ranges} quantity={quantity} rooms={rooms} />
    </div>
  )
}

export default Ranges

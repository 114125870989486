import { LitElement } from 'lit'
import * as React from 'react'
import { render, unmountComponentAtNode } from 'react-dom'

import Schedule from './components/Schedule'
import i18next from './i18n'

import './claror.css'
import './index.scss'

class ClassSchedules extends LitElement {
  static get properties() {
    return {
      locale: { type: String },
      endpoint: { type: String },
      startOf: { type: String },
      interval: { type: Number },
      courseSize: { type: Number },
      courseGap: { type: String },
      scrollTime: { type: Number },
      disableRooms: { type: Boolean },
      scrollActive: { type: Boolean },
      initDate: { type: String },
      mobileMaxWidth: { type: String },
      downloadPdfUrl: { type: String },
    }
  }

  constructor() {
    super()
    this.locale = ''
    this.endpoint = ''
    this.startOf = 'isoWeek'
    this.interval = null
    this.courseSize = 100
    this.courseGap = '5'
    this.disableRooms = false
    this.scrollActive = false
    this.scrollTime = '15000'
    this.initDate = ''
    this.mobileMaxWidth = '600'
    this.downloadPdfUrl = ''
  }

  connectedCallback() {
    this.mount()
  }

  disconnectedCallback() {
    this.unmount()
  }

  update() {
    this.mount()
  }

  mount() {
    const {
      endpoint,
      locale,
      filters,
      startOf,
      interval,
      courseSize,
      courseGap,
      disableRooms,
      scrollActive,
      scrollTime,
      initDate,
      mobileMaxWidth,
      downloadPdfUrl,
    } = this

    const props = {
      endpoint,
      locale,
      selectorFilters: filters,
      startOf,
      interval,
      courseSize,
      courseGap,
      disableRooms,
      scrollActive,
      scrollTime,
      initDate,
      mobileMaxWidth,
      downloadPdfUrl,
    }

    i18next.changeLanguage(locale)

    render(<Schedule {...props} />, this)
  }

  unmount() {
    unmountComponentAtNode(this)
  }
}

customElements.define('class-schedules', ClassSchedules)

import moment from 'moment'
import create from 'zustand'
import { devtools } from 'zustand/middleware'

export const useBaseStore = create(
  devtools((set) => ({
    filters: {
      // Range
      range: null,
      ranges: [],
      // Club
      club: null,
      clubs: [],
      // Type
      type: null,
      types: [],
    },
    filterActions: {
      setFilter: (filter, name) =>
        set(
          (state) => ({ filters: { ...state.filters, [name]: filter } }),
          false,
          'filterActions/setFilterActions',
        ),
    },
    schedules: {
      error: false,
      setError: (error) =>
        set(
          (state) => ({
            schedules: { ...state.schedules, error },
          }),
          false,
          'schedules/setError',
        ),
      loading: true,
      setLoading: (loading) =>
        set(
          (state) => ({
            schedules: { ...state.schedules, loading },
          }),
          false,
          'schedules/setLoading',
        ),
      startDate: moment(new Date()),
      setStartDate: (startDate) =>
        set(
          (state) => ({
            schedules: { ...state.schedules, startDate },
          }),
          false,
          'schedules/setStartDate',
        ),
      schedules: [],
      setSchedules: (schedules) =>
        set(
          (state) => ({
            schedules: { ...state.schedules, schedules },
          }),
          false,
          'schedules/setSchedules',
        ),
      quantity: {},
      setQuantity: (quantity) =>
        set(
          (state) => ({ schedules: { ...state.schedules, quantity } }),
          false,
          'schedules/setQuantity',
        ),
      rooms: {},
      setRooms: (rooms) =>
        set(
          (state) => ({ schedules: { ...state.schedules, rooms } }),
          false,
          'schedules/setRooms',
        ),
    },
    config: {
      endpoint: null,
      setEndpoint: (endpoint) =>
        set(
          (state) => ({ config: { ...state.config, endpoint } }),
          false,
          'config/setEndpoint',
        ),
      interval: null,
      setTimeInterval: (interval) =>
        set(
          (state) => ({ config: { ...state.config, interval } }),
          false,
          'config/setTimeInterval',
        ),
      courseSize: 110,
      setCourseSize: (courseSize) =>
        set(
          (state) => ({ config: { ...state.config, courseSize } }),
          false,
          'config/setCourseSize',
        ),
      courseGap: '5',
      setCourseGap: (courseGap) =>
        set(
          (state) => ({ config: { ...state.config, courseGap } }),
          false,
          'config/setCourseGap',
        ),
      disableRooms: false,
      setDisableRooms: (disableRooms) =>
        set(
          (state) => ({ config: { ...state.config, disableRooms } }),
          false,
          'config/setDisableRooms',
        ),
    },
  })),
)

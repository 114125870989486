/* eslint-disable react-hooks/exhaustive-deps */
import i18next from 'i18next'
import moment from 'moment'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useMedia } from 'react-use'

import { getSchedules } from '../actions/schedules'
import defaultRanges from '../defaultConfig'
import { useBaseStore } from '../store'
import Days from './Days'
import Error from './Error'
import Header from './Header'

const initializeLocale = (locale) => {
  import(`moment/locale/${locale}`)
  moment.locale(locale)
}

function parseStringTemplate(str, obj) {
  const parts = str.split(/\$\{(?!\d)[\wæøåÆØÅ]*\}/)
  const args = str.match(/[^{}]+(?=})/g) || []
  const parameters = args.map(
    (argument) =>
      obj[argument] || (obj[argument] === undefined ? '' : obj[argument]),
  )
  return String.raw({ raw: parts }, ...parameters)
}

const Schedule = (props) => {
  const scheduleBody = useRef()
  const [errorLengthValue, setErrorLength] = useState(false)
  const [downloadUrl, setDownloadUrl] = useState(null)
  const {
    locale,
    // Selectors
    selectorFilters,
    // Configuration
    endpoint,
    interval,
    courseSize,
    courseGap,
    disableRooms,
    scrollActive,
    scrollTime,
    startOf,
    initDate,
    mobileMaxWidth,
    downloadPdfUrl,
  } = props

  const {
    error,
    loading,
    schedules,
    startDate,
    filters,
    setSchedules,
    setQuantity,
    setEndpoint,
    setCourseSize,
    setCourseGap,
    setTimeInterval,
    setStartDate,
    setDisableRooms,
  } = useBaseStore(({ schedules, filters, config }) => ({
    // Schedules
    error: schedules.error,
    loading: schedules.loading,
    schedules: schedules.schedules,
    setSchedules: schedules.setSchedules,
    startDate: schedules.startDate,
    setStartDate: schedules.setStartDate,
    setQuantity: schedules.setQuantity,
    // Filters
    filters,
    // Configuration
    setCourseSize: config.setCourseSize,
    setCourseGap: config.setCourseGap,
    setEndpoint: config.setEndpoint,
    setTimeInterval: config.setTimeInterval,
    setDisableRooms: config.setDisableRooms,
  }))

  const isMobile = useMedia(`(max-width: ${mobileMaxWidth}px)`)
  const scheduleLength = isMobile ? 1 : 6

  const loadConfig = () => {
    if (endpoint) setEndpoint(endpoint)
    if (interval) setTimeInterval(interval)
    if (courseSize) setCourseSize(courseSize)
    if (courseGap) setCourseGap(courseGap)
    if (disableRooms) setDisableRooms(disableRooms)
  }

  const getActiveElement = (element) => {
    if (element.offsetParent !== null) return element
    if (element.nextSibling) return getActiveElement(element.nextSibling)
  }

  const automaticScrolling = (active, quantity) => {
    if (active) {
      let scroll = 0
      let total = 0

      const quantities = Object.values(quantity)
        .reduce((prev, qtt) => {
          const test = qtt.map((q) => {
            return { ...q, quantity: q.quantity * courseSize }
          })

          prev.push(...test)

          return prev
        }, [])
        .reduce((prv, curr) => {
          total += curr.quantity
          prv.push({ ...curr, quantity: total })
          return prv
        }, [])

      setInterval(() => {
        const innerHeight =
          window.innerHeight - scheduleBody.current.previousSibling.clientHeight
        const scrollHeight = scheduleBody.current.scrollHeight

        // Get the closest timeRange to  the next scroll
        const nextQuantity = quantities.reduce((r, a, i, aa) => {
          return i &&
            Math.abs(aa[r].quantity - scroll) < Math.abs(a.quantity - scroll)
            ? r
            : i
        }, -1)

        const scrollElement = getActiveElement(
          document.getElementById(quantities[nextQuantity].nom),
        )

        if (scrollElement) {
          scrollElement.scrollIntoView({
            behavior: 'smooth',
          })
        }

        scroll += innerHeight
        if (scroll >= scrollHeight) scroll = 0
      }, scrollTime)
    }
  }

  const filterRanges = selectorFilters.find(
    (filter) => filter.selectorName === 'ranges',
  )

  const ranges =
    filterRanges && filterRanges.data ? filterRanges.data : defaultRanges

  useEffect(() => {
    initializeLocale(locale)
    loadConfig()
  }, [])

  useEffect(() => {
    if (isMobile) {
      setStartDate(moment(new Date()))
    }

    if (initDate) {
      setStartDate(moment(initDate, 'DD/MM/YYYY'))
    }
  }, [isMobile])

  useEffect(() => {
    getSchedules(
      ranges,
      isMobile
        ? startDate.format('DD/MM/YYYY')
        : startDate.startOf(startOf).format('DD/MM/YYYY'),
      scheduleLength,
    )
      .then(({ schedules, quantity, errorLength }) => {
        setSchedules(schedules)
        setQuantity(quantity)
        setErrorLength(errorLength)
        automaticScrolling(scrollActive, quantity)
      })
      .catch((error) => error)
  }, [scheduleLength, startDate, filters])

  useEffect(() => {
    const formatedInitDate = isMobile
      ? startDate.format('YYYY/MM/DD')
      : startDate.startOf(startOf).format('YYYY/MM/DD')
    const endDate =
      scheduleLength > 1
        ? moment(startDate, 'DD-MM-YYYY')
            .add(scheduleLength, 'days')
            .format('YYYY/MM/DD')
        : moment(startDate, 'DD-MM-YYYY').format('YYYY/MM/DD')
    const parsedUrl = parseStringTemplate(downloadPdfUrl, {
      club: filters?.club?.value,
    })
    setDownloadUrl(
      `${parsedUrl}?initDate=${formatedInitDate}&endDate=${endDate}`,
    )
  })

  const showDownloadPdfButton = downloadPdfUrl && schedules && schedules.length

  return (
    <Fragment>
      {error && <Error error={error} />}
      {loading && (
        <div className='loaderBackdrop'>
          <div className='loader' />
        </div>
      )}
      {!!showDownloadPdfButton && (
        <div className='timetable-pdf'>
          <a className='center-confirm-link' href={downloadUrl}>
            {i18next.t('downloadPdf')}
          </a>
        </div>
      )}
      {!error && schedules && (
        <div className='schedule timetable-component'>
          <Header
            dades={schedules}
            timeRanges={ranges}
            isMobile={isMobile}
            filters={selectorFilters}
          />
          <div className='schedule-body' ref={scheduleBody}>
            {errorLengthValue && <Error type='info' error={errorLengthValue} />}
            {!errorLengthValue && (
              <Days dades={schedules} timeRanges={ranges} isMobile={isMobile} />
            )}
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default Schedule

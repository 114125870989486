import axios from 'axios'
import merge from 'deepmerge'
import moment from 'moment'

import i18next from '../i18n'
import { useBaseStore } from '../store'
import { convertArrayToObject } from '../utils'

function generateTimeIntervals(startString, endString, interval, inclusivity) {
  const start = moment(startString, 'HH:mm')
  const end = moment(endString, 'HH:mm')

  start.minutes(0)

  const result = {}

  const current = moment(start)

  while (current.isBetween(start, end, 'hours', inclusivity)) {
    result[current.format('HH:mm')] = {}
    if (!interval) {
      break
    } else {
      current.add(interval, 'minutes')
    }
  }

  return result
}

function getTimeIntervals(
  startString,
  endString,
  interval,
  classes,
  day,
  inclusivity,
  rooms,
  disableRooms,
) {
  const result = generateTimeIntervals(
    startString,
    endString,
    interval,
    inclusivity,
  )

  if (!day) {
    const times = Object.keys(result).reduce((prev, current, index) => {
      prev[index] = { nom: current }
      return prev
    }, [])

    return times
  }

  let intervalClasses = {}

  if (classes !== undefined) {
    intervalClasses = classes.reduce((previous, course) => {
      const courseTime = moment(course.time, 'HH:mm:ss').minutes(
        Math.floor(moment(course.time, 'HH:mm:ss').minutes() / interval) *
          interval,
      )
      const start = moment(startString, 'HH:mm')
      const end = moment(endString, 'HH:mm')

      if (!interval) {
        if (courseTime.isBetween(start, end, 'hours', inclusivity)) {
          if (previous[start.minutes(0).format('HH:mm')]['defaultRoom']) {
            previous[start.minutes(0).format('HH:mm')]['defaultRoom'] = [
              ...previous[start.minutes(0).format('HH:mm')]['defaultRoom'],
              course,
            ]
          } else {
            previous[start.minutes(0).format('HH:mm')]['defaultRoom'] = [course]
          }
        }
      } else {
        if (previous[courseTime.format('HH:mm')]) {
          if (!disableRooms) {
            if (courseTime.isBetween(start, end, 'hours', inclusivity)) {
              if (previous[courseTime.format('HH:mm')][course.room]) {
                previous[courseTime.format('HH:mm')][course.room] = [
                  ...previous[courseTime.format('HH:mm')][course.room],
                  course,
                ]
              } else {
                previous[courseTime.format('HH:mm')][course.room] = [course]
              }
            }

            previous[courseTime.format('HH:mm')] = {
              ...(rooms[courseTime.format('HH:mm')] &&
                rooms[courseTime.format('HH:mm')].reduce((pr, room) => {
                  pr[room.nom] = []
                  return pr
                }, {})),
              ...previous[courseTime.format('HH:mm')],
            }
          } else {
            if (previous[courseTime.format('HH:mm')]['defaultRoom']) {
              previous[courseTime.format('HH:mm')]['defaultRoom'] = [
                ...previous[courseTime.format('HH:mm')]['defaultRoom'],
                course,
              ]
            } else {
              previous[courseTime.format('HH:mm')]['defaultRoom'] = [course]
            }
          }
        }
      }

      return previous
    }, result)
  }

  return intervalClasses
}

function orderClassesByRange(
  classes,
  timeRanges,
  day,
  interval,
  rooms,
  disableRooms,
) {
  const ranges = timeRanges.reduce((prev, { nom, start, end, inclusivity }) => {
    const res = getTimeIntervals(
      start,
      end,
      interval,
      classes,
      day,
      inclusivity,
      rooms,
      disableRooms,
    )
    prev[nom] = res
    return prev
  }, {})

  return ranges
}

function generateQuantities(days, timeRanges, disableRooms) {
  const quantities = days
    .flatMap((day) => {
      return Object.values(day.ranges).flatMap((range) => {
        return Object.keys(range).map((interval) => {
          let sum = 0
          Object.keys(range[interval]).forEach((t) => {
            sum +=
              range[interval][t].length === 0 || !disableRooms
                ? 1
                : range[interval][t].length
          })
          return {
            nom: interval,
            quantity: sum,
          }
        })
      })
    })
    .reduce((prev, curr) => {
      const found = prev.findIndex((val) => val.nom === curr.nom)
      if (found === -1) {
        prev.push(curr)
      } else {
        prev[found].quantity =
          curr.quantity < prev[found].quantity
            ? prev[found].quantity
            : curr.quantity
      }
      return prev
    }, [])

  const timeRangeQuantities = timeRanges.reduce(
    (timeRangeResult, timeRange) => {
      const { nom, start, end, inclusivity } = timeRange
      const rangeQuantity = quantities.reduce((quantityByRange, quantity) => {
        if (
          moment(quantity.nom, 'HH:mm').isBetween(
            moment(start, 'HH:mm'),
            moment(end, 'HH:mm'),
            'hours',
            inclusivity,
          )
        ) {
          quantityByRange = [...quantityByRange, quantity]
        }

        return quantityByRange
      }, [])
      timeRangeResult[nom] = rangeQuantity
      return timeRangeResult
    },
    {},
  )

  return timeRangeQuantities
}

export async function getSchedules(timeRanges, startDate, length) {
  const { endpoint, interval, disableRooms } = useBaseStore.getState().config
  const { setLoading, setError } = useBaseStore.getState().schedules
  const filters = useBaseStore.getState().filters
  const initDate = moment(startDate, 'DD-MM-YYYY').format('YYYY/MM/DD')
  const endDate =
    length > 1
      ? moment(startDate, 'DD-MM-YYYY').add(length, 'days').format('YYYY/MM/DD')
      : moment(startDate, 'DD-MM-YYYY').format('YYYY/MM/DD')

  const allFilters = Object.keys(filters).reduce((prev, filter) => {
    if (Array.isArray(filters[filter])) return prev
    prev[filter] = filters[filter] !== null ? filters[filter].value : null
    return prev
  }, {})

  setLoading(true)
  setError(false)

  return await axios
    .get(`${endpoint}`, {
      params: {
        initDate,
        endDate,
        ...allFilters,
      },
    })
    .then(({ data }) => {
      let schedules = []
      let quantity = []
      let rums = []
      const ranges = convertArrayToObject(timeRanges)
      if (data.schedules && data.schedules.length) {
        const rooms = generateRooms(data.schedules, disableRooms, interval)
        const days = data.schedules.map(({ day, classes }) => {
          const ranges = orderClassesByRange(
            classes,
            timeRanges,
            day,
            interval,
            rooms,
            disableRooms,
          )

          return {
            day,
            ranges,
          }
        })
        quantity = generateQuantities(days, timeRanges, disableRooms)
        rums = generateRums(rooms, timeRanges, interval)
        const calendarRooms = {
          day: null,
          name: i18next.t('rooms'),
          ranges: rums,
        }

        const calendarRanges = {
          day: null,
          name: i18next.t('schedules'),
          ranges: quantity,
        }
        schedules = [
          { day: null, ranges },
          ...(interval ? [calendarRanges] : []),
          ...(!disableRooms ? [calendarRooms] : []),
          ...days,
        ]
      }

      setLoading(false)

      return { schedules, quantity, errorLength: data?.error, rums }
    })
    .catch((error) => {
      console.log(error)
      setError({ title: 'Error', message: error.message })
      setLoading(false)
      return error
    })
}

function generateRums(rooms, timeRanges, interval) {
  const rums = timeRanges.reduce((prev, timeRange) => {
    const timeIntervals = getTimeIntervals(
      timeRange.start,
      timeRange.end,
      interval,
    )
    const emptyIntervals = timeIntervals.reduce((pv, inval) => {
      pv[inval.nom] = []
      return pv
    }, {})

    const intervalWithRooms = Object.keys(rooms).reduce((pr, roomInterval) => {
      if (
        moment(roomInterval, 'HH:mm').isBetween(
          moment(timeRange.start, 'HH:mm'),
          moment(timeRange.end, 'HH:mm'),
          'hours',
          timeRange.inclusivity,
        )
      ) {
        pr[roomInterval] = [...rooms[roomInterval]]
      }
      return pr
    }, emptyIntervals)

    const orderedIntervalWithRooms = Object.keys(intervalWithRooms)
      .sort()
      .reduce((obj, key) => {
        obj[key] = intervalWithRooms[key]
        return obj
      }, {})

    prev[timeRange.nom] = orderedIntervalWithRooms

    return prev
  }, {})
  return rums
}

function generateRooms(data, disableRooms, interval) {
  const rooms = data.reduce((prev, dt) => {
    let classes = {}
    if (dt.classes !== undefined) {
      classes = dt.classes.reduce((obj, course) => {
        const room = disableRooms ? 'defaultRoom' : course.room
        const courseTime = moment(course.time, 'HH:mm:ss')
          .minutes(
            Math.floor(moment(course.time, 'HH:mm:ss').minutes() / interval) *
              interval,
          )
          .format('HH:mm')
        generateRoomsWithQuantities(obj, courseTime, room)
        return obj
      }, {})
    }
    prev = merge(prev, classes)
    return prev
  }, {})

  const mergedRooms = Object.keys(rooms).reduce((prev, curr) => {
    if (!typeof curr === 'object' || curr === null) {
      return prev
    }
    rooms[curr].forEach((key) => {
      if (prev[curr]) {
        const index = prev[curr].findIndex((val) => val.nom === key.nom)
        if (index > -1) {
          if (key.quantity > prev[curr][index].quantity) {
            prev[curr][index] = key
          }
        } else {
          prev[curr] = [...prev[curr], key]
        }
      } else {
        prev[curr] = [key]
      }
    })

    return prev
  }, {})

  return mergedRooms
}

function generateRoomsWithQuantities(newRooms, courseTime, room) {
  if (newRooms[courseTime]) {
    const index = newRooms[courseTime].findIndex((val) => val.nom === room)
    if (index >= 0) {
      newRooms[courseTime][index] = {
        ...newRooms[courseTime][index],
        quantity: newRooms[courseTime][index].quantity + 1,
      }
    } else {
      newRooms[courseTime].push({
        nom: room,
        quantity: 1,
      })
    }
  } else {
    newRooms[courseTime] = [
      {
        nom: room,
        quantity: 1,
      },
    ]
  }
}

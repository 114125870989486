const defaultRanges = [
  {
    nom: 'all',
    className: 'all-range',
    start: '7:00',
    end: '23:00',
    inclusivity: '[]',
    label: 'all',
    value: 'all',
  },
]

export default defaultRanges

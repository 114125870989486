import classNames from 'classnames'
import moment from 'moment'
import React from 'react'
import slugify from 'slugify'

import { useBaseStore } from '../store'
import { getTimeRangeQuantity } from './Ranges'

function onMouseAction(event) {
  return (range, action) => {
    const courses = document.querySelectorAll('.course')
    courses.forEach((course) => {
      if (
        range.time &&
        course.dataset.type === event.currentTarget.dataset.type
      )
        course.classList[action]('hovered')
    })
  }
}

function useClassNames(range) {
  if (!range) return
  const typeName = `${[range.type]}`
  const intensityName = `${[range.intensity]}`
  const roomName = `${[range.room]}`
  const intensity = `intensity-${slugify(intensityName.toLowerCase())}`
  const type = `type-${slugify(typeName.toLowerCase())}`
  const room = `room-${slugify(roomName.toLowerCase())}`
  const courseClass = classNames({
    'range-title': !range.time && range.nom,
    course: range.time,
    [type]: range.type,
    [intensity]: range.intensity,
    [room]: range.room,
  })
  return { courseClass }
}

function generateDataAttributes(range) {
  return Object.keys(range).reduce((prev, rg) => {
    prev[`data-${rg.toLowerCase()}`] = range[rg]
    return prev
  }, {})
}

export const Course = (props) => {
  const { range, quantity } = props
  const { courseClass } = useClassNames(range)
  const { courseSize, courseGap } = useBaseStore(({ config }) => ({
    courseSize: config.courseSize,
    courseGap: config.courseGap,
  }))

  const timeRangeQuantity = getTimeRangeQuantity(quantity, range.nom)
  const height =
    timeRangeQuantity * courseSize + (timeRangeQuantity - 1) * courseGap

  return (
    <div
      id={range.nom}
      className={courseClass}
      style={{
        backgroundColor: range.color,
        height: `${height}px`,
        minHeight: `${height}px`,
        display: timeRangeQuantity === 0 && 'none',
      }}
      type={range.type}
      onMouseEnter={(e) => onMouseAction(e)(range, 'add')}
      onMouseLeave={(e) => onMouseAction(e)(range, 'remove')}
      {...generateDataAttributes(range)}
    >
      <div className='range-title-wrapper'>
        <div className='actnam'>{range.nom}</div>
        <div className='acttim'>
          {range.time ? moment(range.time, 'HH:mm:ss').format('HH:mm') : null}
        </div>
        <div className='actpla'>{range.room}</div>
        <div className='actdur'>{range.duracio}</div>
        <div className='actinfo'></div>
      </div>
    </div>
  )
}

export default Course

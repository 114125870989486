import React, { useEffect } from 'react'
import Select from 'react-select'

import { useBaseStore } from '../store'

function getCookie(cname) {
  const name = cname + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

const Selector = (props) => {
  const {
    name,
    options,
    defaultValue,
    filterName,
    placeholder,
    onChange,
    isClearable,
    className,
  } = props
  const { setFilter } = useBaseStore(({ filterActions }) => ({
    setFilter: filterActions.setFilter,
  }))

  useEffect(() => {
    setFilter(defaultValue, filterName)
  }, [defaultValue, filterName, setFilter])

  if (options.length <= 1) return null

  return (
    <Select
      className={`selector-${className}`}
      isSearchable
      isClearable={isClearable}
      name={name}
      options={options}
      defaultValue={defaultValue}
      placeholder={placeholder}
      onChange={onChange}
    />
  )
}

const Filters = (props) => {
  const { filters } = props
  const { setFilter } = useBaseStore(({ filterActions }) => ({
    setFilter: filterActions.setFilter,
  }))

  const daysToExpire = new Date(2147483647 * 1000).toUTCString()

  const getDefaultValue = (filter) => {
    const defaultValue = filter.defaultValue
      ? filter.defaultValue
      : filter.filterName === 'club' && getCookie('club')
      ? filter.data.find(
          (val) =>
            val.label === getCookie('club') || val.value === getCookie('club'),
        )
      : null

    return defaultValue
  }

  return (
    <div className='header-filter'>
      {filters.map((filter, index) => {
        if (filter && filter.data && !filter.data.length) return null
        return (
          <Selector
            key={index}
            isSearchable
            isClearable={
              filter.isClearable || filter.isClearable === false
                ? filter.isClearable
                : true
            }
            className={filter.selectorName}
            name={filter.selectorName}
            options={filter.data}
            defaultValue={getDefaultValue(filter)}
            filterName={filter.filterName}
            placeholder={filter.placeholder}
            onChange={(value) => {
              document.cookie = `${filter.filterName}=${
                value ? value.label : null
              }; path=/; expires=${daysToExpire}`
              setFilter(value, filter.filterName)
            }}
          />
        )
      })}
    </div>
  )
}

export default Filters

import React from 'react'
import PropTypes from 'prop-types'
import { FaTimesCircle, FaInfoCircle } from 'react-icons/fa'

function Icon({ type }) {
  switch (type) {
    case 'danger':
      return <FaTimesCircle style={{ fontSize: '25px' }} />
    case 'info':
      return <FaInfoCircle style={{ fontSize: '25px' }} />
    default:
      return null
  }
}

function Error(props) {
  const {
    error: { title = 'Error', message = 'Something happened' },
    type = 'danger',
  } = props

  function getTypeStyle(type) {
    switch (type) {
      case 'danger':
        return {
          backgroundColor: '#f8d7da',
          borderColor: '#f5c2c7',
          color: '#842029',
        }
      case 'info':
        return {
          backgroundColor: '#cff4fc',
          borderColor: '#b6effb',
          color: '#055160',
        }
      default:
        return null
    }
  }

  return (
    <div className='error-wrapper'>
      <div
        style={{
          display: 'flex',
          gap: '15px',
          border: '1px solid',
          borderRadius: '.25rem',
          padding: '1rem',
          ...getTypeStyle(type),
        }}
      >
        <div>
          <Icon type={type} />
        </div>
        <div>
          <div>{title}</div>
          <div>{message}</div>
        </div>
      </div>
    </div>
  )
}

Error.propTypes = {
  error: PropTypes.shape({
    title: PropTypes.string,
    message: PropTypes.string,
  }),
  type: PropTypes.string,
}

export default Error

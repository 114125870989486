import moment from 'moment'
import React from 'react'
import { FaCaretLeft, FaCaretRight } from 'react-icons/fa'

import { useBaseStore } from '../store'

export const Navigation = (props) => {
  const { isMobile, startOf } = props
  const { setStartDate, startDate } = useBaseStore(({ schedules }) => ({
    startDate: schedules.startDate,
    setStartDate: schedules.setStartDate,
  }))

  return (
    <div className='header-navigation'>
      <div
        className='leftArrow'
        onClick={() =>
          setStartDate(
            moment(startDate, 'DD/MM/YYYY')
              .startOf(startOf)
              .subtract(isMobile ? 1 : 7, 'days'),
          )
        }
      >
        <FaCaretLeft />
      </div>
      <div
        className='rightArrow'
        onClick={() => {
          setStartDate(
            moment(startDate, 'DD/MM/YYYY')
              .startOf(startOf)
              .add(isMobile ? 1 : 7, 'days'),
          )
        }}
      >
        <FaCaretRight />
      </div>
    </div>
  )
}

export default Navigation

import React from 'react'

import { useBaseStore } from '../store'
import Ranges from './Ranges'

export const Days = (props) => {
  const { dades, timeRanges } = props
  const { range, quantity, rooms } = useBaseStore(({ filters, schedules }) => ({
    range: filters.range,
    quantity: schedules.quantity,
    rooms: schedules.rooms,
  }))

  return (
    <div className='day-row'>
      {timeRanges.map(({ nom }, timeRangeIndex) => {
        // Apply range filter
        if (!range || (range && nom === range.label)) {
          const totalQuantities =
            quantity[nom] &&
            quantity[nom].map((q) => q.quantity).reduce((a, b) => a + b)
          return (
            <div
              style={{
                display: totalQuantities === 0 && 'none',
              }}
              key={timeRangeIndex}
              className={`range ${nom}`}
            >
              {dades &&
                dades.map(({ day, ranges }, index) => {
                  return (
                    <Ranges
                      quantity={quantity && quantity[nom]}
                      rooms={rooms && rooms[nom]}
                      ranges={ranges[nom]}
                      key={index}
                      day={day}
                      id={index}
                    />
                  )
                })}
            </div>
          )
        }
        return null
      })}
    </div>
  )
}

export default Days
